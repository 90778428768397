define('editorium/components/cards/terracycle-widget-card/component', ['exports', 'ember', 'editorium/components/card/component', 'ember-utils', 'editorium/components/cards/terracycle-widget-card/template'], function (exports, _ember, _editoriumComponentsCardComponent, _emberUtils, _editoriumComponentsCardsTerracycleWidgetCardTemplate) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsTerracycleWidgetCardTemplate['default'],

    terracycleWidgetManager: _ember['default'].inject.service(),

    defaultProps: {
      resourceId: null,
      locale: null,
      version: 'sdk_v3' },

    // 'sdk_v3' by default
    locales: [{ code: 'en-AU', name: 'Australia (en-AU)' }, { code: 'zh-CN', name: 'China (zh-CN)' }, { code: 'ja-JP', name: 'Japan (ja-JP)' }, { code: 'ko-KR', name: 'Korea (ko-KR)' }, { code: 'en-NZ', name: 'New Zealand (en-NZ)' }, { code: 'nl-BE', name: 'Belgium (Dutch) (nl-BE)' }, { code: 'fr-BE', name: 'Belgium (French) (fr-BE)' }, { code: 'da-DK', name: 'Denmark (da-DK)' }, { code: 'de-DE', name: 'Germany (de-DE)' }, { code: 'es-ES', name: 'Spain (es-ES)' }, { code: 'fr-FR', name: 'France (fr-FR)' }, { code: 'en-IE', name: 'Ireland (en-IE)' }, { code: 'nl-NL', name: 'Netherlands (nl-NL)' }, { code: 'no-NO', name: 'Norway (no-NO)' }, { code: 'de-AT', name: 'Austria (de-AT)' }, { code: 'fr-CH', name: 'Switzerland (French) (fr-CH)' }, { code: 'de-CH', name: 'Switzerland (German) (de-CH)' }, { code: 'sv-SE', name: 'Sweden (sv-SE)' }, { code: 'en-GB', name: 'United Kingdom (en-GB)' }, { code: 'pt-BR', name: 'Brazil (pt-BR)' }, { code: 'en-CA', name: 'Canada (English) (en-CA)' }, { code: 'fr-CA', name: 'Canada (French) (fr-CA)' }, { code: 'en-US', name: 'United States (en-US)' }],

    actions: {
      updateWidgetSettings: function updateWidgetSettings(resourceId, locale) {

        if ((0, _emberUtils.isBlank)(resourceId) || (0, _emberUtils.isBlank)(locale)) {
          window.alert('Invalid Resource ID or Locale');
          return;
        }

        this.setProperties({
          'props.resourceId': resourceId,
          'props.locale': locale
        });

        this.setupWidget();

        this.send('doneEditing');
        this.commitStore();
      },

      removeCard: function removeCard() {
        this.cleanupWidget();
        this._super.apply(this, arguments);
      }
    },

    initNewCard: function initNewCard() {
      this.send('edit');
    },

    didDoneEditing: function didDoneEditing() {
      if ((0, _emberUtils.isBlank)(this.get('props.resourceId')) || (0, _emberUtils.isBlank)(this.get('props.locale'))) {
        this.send('removeCard');
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupWidget();
    },

    setupWidget: function setupWidget() {
      var _get = this.get('props');

      var resourceId = _get.resourceId;
      var locale = _get.locale;

      if ((0, _emberUtils.isBlank)(resourceId) || (0, _emberUtils.isBlank)(locale)) {
        return;
      }

      this.get('terracycleWidgetManager').loadTerraCycleScript(locale)['catch'](function (error) {
        return console.error(error);
      });
    },

    cleanupWidget: function cleanupWidget() {
      var terracycleManager = this.get('terracycleWidgetManager');
      if (terracycleManager) {
        var terracycleInstance = window.terracycleV3;

        if (terracycleInstance && typeof terracycleInstance.cleanup === 'function') {
          terracycleInstance.cleanup();
        }

        terracycleManager.removeScript();
      }
    }
  });
});