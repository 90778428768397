define('editorium/components/terracycle-widget/component', ['exports', 'ember', 'ember-component'], function (exports, _ember, _emberComponent) {
  exports['default'] = _emberComponent['default'].extend({
    terracycleWidgetManager: _ember['default'].inject.service(),

    resourceId: null,
    locale: null,

    widgetClass: 'terracycle-widget-v3',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.scheduleOnce('afterRender', this, this.setupWidget);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      _ember['default'].run.once(this, this.setupWidget);
    },

    setupWidget: function setupWidget() {
      var _this = this;

      var _getProperties = this.getProperties('resourceId', 'locale');

      var resourceId = _getProperties.resourceId;
      var locale = _getProperties.locale;

      if (!resourceId || !locale) {
        return;
      }

      var scriptPromise = this.get('terracycleWidgetManager').loadTerraCycleScript(locale);

      scriptPromise.then(function () {
        // Always initialize the widget, even if the script was already loaded
        _this.initializeWidget();
      })['catch'](function (error) {
        return console.error(error);
      });
    },

    initializeWidget: function initializeWidget() {
      var widgetClass = this.get('widgetClass');
      var widgetElement = this.$('.' + widgetClass)[0]; // Get the DOM element

      if (!widgetElement) {
        console.error('Widget element not found during initialization.');
        return;
      }

      // Initialize the widget for sdk_v3
      if (window.terracycleV3 && typeof window.terracycleV3.initWidget === 'function') {
        window.terracycleV3.initWidget(widgetElement);
      }
    }
  });
});