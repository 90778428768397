define('editorium/components/cards/gog-widget-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/gog-widget-card/template', 'ember-utils'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsGogWidgetCardTemplate, _emberUtils) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsGogWidgetCardTemplate['default'],

    defaultProps: {
      apiKey: null
    },

    actions: {
      updateWidgetSettings: function updateWidgetSettings(apiKey) {
        if ((0, _emberUtils.isBlank)(apiKey)) {
          window.alert('Invalid API Key');
          return;
        }

        this.setProperties({
          'props.apiKey': apiKey
        });

        this.send('doneEditing');
        this.commitStore();
      },

      removeCard: function removeCard() {
        this._super.apply(this, arguments);
      }
    },

    initNewCard: function initNewCard() {
      this.send('edit');
    },

    didDoneEditing: function didDoneEditing() {
      if ((0, _emberUtils.isBlank)(this.get('props.apiKey'))) {
        this.send('removeCard');
      }
    }
  });
});