define('editorium/services/terracycle-widget-manager', ['exports', 'ember-service', 'rsvp', 'editorium/config/environment'], function (exports, _emberService, _rsvp, _editoriumConfigEnvironment) {
  exports['default'] = _emberService['default'].extend({
    loadTerraCycleScript: function loadTerraCycleScript(locale) {
      return new _rsvp['default'].Promise(function (resolve, reject) {
        var script = document.createElement('script');

        // Ensure there's a slash between base URL and locale
        var baseUrl = _editoriumConfigEnvironment['default'].widgetBaseUrl;
        if (!baseUrl.endsWith('/')) {
          baseUrl += '/';
        }

        // Construct the script source URL
        script.src = '' + baseUrl + locale + '/sdk_v3.js';

        script.async = true;

        script.onload = function () {
          resolve();
        };

        script.onerror = function (error) {
          return reject(new Error('Failed to load the TerraCycle script for locale ' + locale + ': ' + error.message));
        };

        document.body.appendChild(script);
      });
    },

    removeScript: function removeScript() {
      if (this.get('scriptInserted')) {
        var script = this.get('currentScript');
        if (script) {
          script.parentNode.removeChild(script);
        }
        this.set('scriptInserted', false);
        this.set('currentScript', null);
      }
    }
  });
});