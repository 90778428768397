define('editorium/components/cards/hubspot-form-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/hubspot-form-card/template', 'ember-utils'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsHubspotFormCardTemplate, _emberUtils) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsHubspotFormCardTemplate['default'],

    // Provide default props so users see prefilled values for region/portalId
    defaultProps: {
      region: 'na1', // default region
      portalId: '6369378', // default portalId
      formId: null
    },

    actions: {
      // The user updates the form settings in the modal
      updateWidgetSettings: function updateWidgetSettings(region, portalId, formId) {
        if ((0, _emberUtils.isBlank)(formId)) {
          window.alert('Invalid form ID');
          return;
        }

        this.setProperties({
          'props.region': region,
          'props.portalId': portalId,
          'props.formId': formId
        });

        // Save and exit edit mode
        this.send('doneEditing');
        this.commitStore();
      },

      removeCard: function removeCard() {
        // Standard remove card action
        this._super.apply(this, arguments);
      }
    },

    initNewCard: function initNewCard() {
      // Immediately open edit mode when inserting a new card
      this.send('edit');
    },

    didDoneEditing: function didDoneEditing() {
      // If we never got a valid formId, remove this card from the editor
      if ((0, _emberUtils.isBlank)(this.get('props.formId'))) {
        this.send('removeCard');
      }
    }
  });
});