define("editorium/components/terracycle-widget/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "editorium/components/terracycle-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "data-type", "ocsl");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'data-resource-id');
        morphs[2] = dom.createAttrMorph(element0, 'data-locale');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["get", "widgetClass", ["loc", [null, [1, 14], [1, 25]]]]]]], ["attribute", "data-resource-id", ["concat", [["get", "resourceId", ["loc", [null, [3, 25], [3, 35]]]]]]], ["attribute", "data-locale", ["concat", [["get", "locale", ["loc", [null, [4, 20], [4, 26]]]]]]]],
      locals: [],
      templates: []
    };
  })());
});