define('editorium/components/cards/video-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/video-card/utils', 'ember-computed', 'ember-utils', 'ember-runloop'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsVideoCardUtils, _emberComputed, _emberUtils, _emberRunloop) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    classNames: ['video-card'],

    defaultProps: {
      style: 'default',
      caption: ''
    },

    videoId: (0, _emberComputed['default'])('props.value', function () {
      var value = this.get('props.value');
      return (0, _editoriumComponentsCardsVideoCardUtils.extractId)(value);
    }),

    previewSrc: (0, _emberComputed['default'])('videoId', function () {
      var videoId = this.get('videoId');
      return 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg';
    }),

    shouldShowCaption: (0, _emberComputed['default'])('isFocused', 'props.caption', function () {
      var isEditing = this.get('isFocused');
      var caption = this.get('props.caption');
      return caption || isEditing;
    }),

    isEditingValue: false,
    isEditingCaption: false,

    actions: {
      editValue: function editValue() {
        var _this = this;

        (0, _emberRunloop.next)(function () {
          _this.set('isEditingValue', true);
        });
      },

      doneEditingValue: function doneEditingValue() {
        this.set('isEditingValue', false);
      },

      updateValue: function updateValue(value) {
        if ((0, _emberUtils.isBlank)((0, _editoriumComponentsCardsVideoCardUtils.extractId)(value))) {
          this.send('removeCard');
        } else {
          this.set('props.value', value);
          this.send('doneEditing');
        }
      },

      selectStyle: function selectStyle(value) {
        this.set('props.style', value);
      },

      editCaption: function editCaption() {
        var _this2 = this;

        (0, _emberRunloop.next)(function () {
          _this2.set('isEditingCaption', true);
        });
      },

      doneEditingCaption: function doneEditingCaption() {
        var _this3 = this;

        (0, _emberRunloop.next)(function () {
          _this3.set('isEditingCaption', false);
        });
      },

      updateCaption: function updateCaption(caption) {
        this.set('props.caption', caption);
        this.commitStore();
        this.send('doneEditingCaption');
      }
    },

    didBecameEditing: function didBecameEditing() {
      this.send('editValue', true);
    },

    didDoneEditing: function didDoneEditing() {
      this.send('doneEditingValue');
      this.commitStore();
    },

    initNewCard: function initNewCard() {
      this.send('edit');
    }
  });
});