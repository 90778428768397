define("editorium/fixtures/data", ["exports"], function (exports) {
  exports["default"] = {
    "version": "0.2.0",
    "sections": [[], [[10, "video", {
      "value": "DrFXw0QGDLM",
      "style": "default"
    }], [10, "icon-grid", {
      "items": [{
        "type": "pdf",
        "label": "Resource with custom thumbnail",
        "url": "https://s3.amazonaws.com/tc-us-prod/download_resource/downloads/1519/TNG_DIY_Earrings.pdf",
        "thumbnailUrl": "https://unsplash.it/200"
      }, {
        "type": "pdf",
        "label": "Regular resource",
        "url": "https://s3.amazonaws.com/tc-us-prod/download_resource/downloads/1519/TNG_DIY_Earrings.pdf",
        "thumbnailUrl": ""
      }]
    }], [10, "text", {
      "block": "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, neque cum ullam. Iusto, nihil, nesciunt consectetur quae dolores, ratione adipisci voluptatibus, iste omnis soluta culpa voluptas quasi dolorum officiis ex.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, neque cum ullam. Iusto, nihil, nesciunt consectetur quae dolores, ratione adipisci voluptatibus, iste omnis soluta culpa voluptas quasi dolorum officiis ex.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, neque cum ullam. Iusto, nihil, nesciunt consectetur quae dolores, ratione adipisci voluptatibus, iste omnis soluta culpa voluptas quasi dolorum officiis ex.</p>"
    }], [10, "media-objects", {
      "items": [{
        "title": "First Shipment Bonus",
        "program": "Some Brigade",
        "timeline": "Varies per program",
        "prize": "Varies per program",
        "howToWin": "Send in your first shipment through qualifying Brigade programs. All shipment sizes count!",
        "imageUrl": "https://unsplash.it/64",
        "url": "https://terracycle.com"
      }, {
        "title": "Summer Send-In",
        "program": "Some ZWB",
        "timeline": "July and August",
        "prize": "100 bonus points per shipment",
        "howToWin": "Send in your first shipment through qualifying Brigade programs.",
        "imageUrl": "https://unsplash.it/64?image=100",
        "url": "https://terracycle.com"
      }]
    }], [10, "image", {
      "src": "https://unsplash.it/1140/562",
      "width": 1140,
      "height": 562,
      "caption": "Hello",
      "layout": "full-width",
      "href": "https://example.com",
      "hrefTargetBlank": true,
      "mobileSrc": "https://unsplash.it/1140/560",
      "mobileWidth": 1140,
      "mobileHeight": 560
    }]]]
  };
});