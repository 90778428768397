define("editorium/components/cards/terracycle-widget-card/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 15,
                "column": 6
              }
            },
            "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "copy-card-button", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [13, 33], [13, 38]]]]], [], []]], ["loc", [null, [13, 8], [13, 40]]]], ["inline", "edit-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [14, 34], [14, 38]]]]], [], []]], ["loc", [null, [14, 8], [14, 40]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 25,
              "column": 2
            }
          },
          "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "terracycleWidgetCard");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "terracycleWidgetCard_reorder");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "terracycleWidgetCard_iframeWrapper");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "terracycleWidgetCard_iframeOverlay");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element6, 'onclick');
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(element6, 3, 3);
          morphs[3] = dom.createMorphAt(element6, 5, 5);
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [7]), 3, 3);
          return morphs;
        },
        statements: [["attribute", "onclick", ["subexpr", "action", ["focus"], [], ["loc", [null, [5, 46], [5, 64]]]]], ["inline", "reorder-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [7, 37], [7, 41]]]]], [], []]], ["loc", [null, [7, 8], [7, 43]]]], ["inline", "remove-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [10, 34], [10, 38]]]]], [], []]], ["loc", [null, [10, 6], [10, 40]]]], ["block", "if", [["get", "isFocused", ["loc", [null, [12, 12], [12, 21]]]]], [], 0, null, ["loc", [null, [12, 6], [15, 13]]]], ["inline", "terracycle-widget", [], ["resourceId", ["subexpr", "@mut", [["get", "props.resourceId", ["loc", [null, [20, 21], [20, 37]]]]], [], []], "locale", ["subexpr", "@mut", [["get", "props.locale", ["loc", [null, [21, 17], [21, 29]]]]], [], []]], ["loc", [null, [19, 8], [22, 10]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 12
                  },
                  "end": {
                    "line": 51,
                    "column": 12
                  }
                },
                "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element0, 'value');
                morphs[1] = dom.createAttrMorph(element0, 'selected');
                morphs[2] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "locale.code", ["loc", [null, [50, 30], [50, 41]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "locale.code", ["loc", [null, [50, 58], [50, 69]]]], ["get", "content.locale", ["loc", [null, [50, 70], [50, 84]]]]], [], ["loc", [null, [50, 53], [50, 86]]]]], ["content", "locale.name", ["loc", [null, [50, 87], [50, 102]]]]],
              locals: ["locale"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 4
                },
                "end": {
                  "line": 69,
                  "column": 4
                }
              },
              "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "terracycleWidgetCard_modalBody");
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment(" Resource ID input ");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "form-group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "for", "resource-id");
              var el4 = dom.createTextNode("Resource ID:");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment(" Locale Select ");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "form-group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "for", "locale-select");
              var el4 = dom.createTextNode("Select Locale:");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("select");
              dom.setAttribute(el3, "id", "locale-select");
              dom.setAttribute(el3, "class", "form-control");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("option");
              dom.setAttribute(el4, "value", "");
              dom.setAttribute(el4, "disabled", "");
              dom.setAttribute(el4, "selected", "");
              var el5 = dom.createTextNode("Select a locale");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "terracycleWidgetCard_modalActions");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment(" Cancel Button ");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "terracycleWidgetCard_cancelButton btn btn-default");
              var el4 = dom.createTextNode("\n            Cancel\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment(" Save Button ");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "terracycleWidgetCard_submitButton btn btn-primary");
              var el4 = dom.createTextNode("\n            Save\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [7, 3]);
              var element3 = dom.childAt(element1, [9]);
              var element4 = dom.childAt(element3, [3]);
              var element5 = dom.childAt(element3, [7]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [3]), 3, 3);
              morphs[1] = dom.createAttrMorph(element2, 'onchange');
              morphs[2] = dom.createMorphAt(element2, 3, 3);
              morphs[3] = dom.createAttrMorph(element4, 'onclick');
              morphs[4] = dom.createAttrMorph(element5, 'onclick');
              return morphs;
            },
            statements: [["inline", "input", [], ["id", "resource-id", "value", ["subexpr", "@mut", [["get", "content.resourceId", ["loc", [null, [38, 18], [38, 36]]]]], [], []], "placeholder", "198bf8db-1a5c-42f4-9787-536d0f1f9e06", "class", "form-control"], ["loc", [null, [36, 10], [41, 12]]]], ["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "content.locale", ["loc", [null, [47, 81], [47, 95]]]]], [], ["loc", [null, [47, 76], [47, 96]]]]], ["value", "target.value"], ["loc", [null, [47, 67], [47, 119]]]]], ["block", "each", [["get", "locales", ["loc", [null, [49, 20], [49, 27]]]]], [], 0, null, ["loc", [null, [49, 12], [51, 21]]]], ["attribute", "onclick", ["subexpr", "action", ["doneEditing"], [], ["loc", [null, [57, 26], [57, 50]]]]], ["attribute", "onclick", ["subexpr", "action", ["updateWidgetSettings", ["get", "content.resourceId", ["loc", [null, [63, 58], [63, 76]]]], ["get", "content.locale", ["loc", [null, [63, 77], [63, 91]]]]], [], ["loc", [null, [63, 26], [63, 93]]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 2
              },
              "end": {
                "line": 70,
                "column": 2
              }
            },
            "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-dialog", [], [], 0, null, ["loc", [null, [30, 4], [69, 21]]]]],
          locals: ["content"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 71,
              "column": 0
            }
          },
          "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "buffered-proxy", [], ["content", ["subexpr", "@mut", [["get", "props", ["loc", [null, [29, 28], [29, 33]]]]], [], []]], 0, null, ["loc", [null, [29, 2], [70, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element7, 'class');
        morphs[2] = dom.createMorphAt(element7, 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "add-cards", [], ["index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [1, 18], [1, 23]]]]], [], []]], ["loc", [null, [1, 0], [1, 25]]]], ["attribute", "class", ["concat", ["terracycleWidgetCard_wrapper ", ["subexpr", "if", [["get", "isFocused", ["loc", [null, [3, 46], [3, 55]]]], "_is_focused"], [], ["loc", [null, [3, 41], [3, 71]]]]]]], ["block", "click-outside", [], ["action", ["subexpr", "action", ["blur"], [], ["loc", [null, [4, 26], [4, 41]]]], "except-selector", "#modal-overlays"], 0, null, ["loc", [null, [4, 2], [25, 20]]]], ["block", "if", [["get", "isEditing", ["loc", [null, [28, 6], [28, 15]]]]], [], 1, null, ["loc", [null, [28, 0], [71, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});