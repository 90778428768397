define('editorium/components/cards/text-card/component', ['exports', 'editorium/components/card/component', 'ember-utils'], function (exports, _editoriumComponentsCardComponent, _emberUtils) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    classNames: ['text-card'],

    defaultProps: {
      block: '',
      textStyle: 'normal' // normal, lead
    },

    useCKEditor: true,
    ckeditorConfig: {
      language: 'en',
      allowedContent: 'h2(*); h3(*); h4(*); h5(*); h6(*); blockquote(*); p{text-align}(*); strong(*); em(*); b(*); i(*); del(*); ins(*); u(*); sup(*); sub(*); ol(*); ul(*); ol(*); li(*); small(*); a[target,href,name](*); br(*); hr(*); img{*}[*](*); iframe(*)',
      disallowedContent: '*[on*]',
      toolbar: [{ name: 'basicstyles', items: ['Bold', 'Italic', '-', 'Subscript', 'Superscript', '-', 'RemoveFormat'] }, { name: 'links', items: ['Link', 'Unlink', 'Anchor'] }, { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] }, { name: 'styles', items: ['Format', 'Styles'] }, { name: 'misc', items: ['SpecialChar'] }],
      toolbarGroups: [{ name: 'basicstyles', groups: ['basicstyles'] }, { name: 'links', groups: ['links'] }, { name: 'paragraph', groups: ['paragraph'] }, { name: 'styles', groups: ['styles'] }, { name: 'misc', groups: ['misc'] }],
      removeButtons: '',
      removePlugins: 'elementspath,resize',
      extraPlugins: 'autogrow,justify',
      resize_enabled: false,
      format_tags: 'h2;h3;h4;h5;h6;p'
    },

    actions: {
      editorBecameReady: function editorBecameReady() {
        this.$('.cke_editable').focus();
      },

      // Note that `updateText` is fired for each keystroke!
      updateText: function updateText(val) {
        var value = val.replace(/^\s+|\s+$/g, '');
        this.setErrorState((0, _emberUtils.isBlank)(value));
        this.set('props.block', value);
      },

      // Style
      selectStyle: function selectStyle(value) {
        this.set('props.textStyle', value);
        this.commitStore();
      }
    },

    initNewCard: function initNewCard() {
      this.send('edit');
    },

    didDoneEditing: function didDoneEditing() {
      var text = this.get('props.block');
      var tempElement = document.createElement('div');
      tempElement.innerHTML = text;

      if (/^\s*$/.test(tempElement.innerText)) {
        this.removeFromStore();
      }

      this.commitStore();
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().on('click', '.textCard_renderedContent a', this._didClickLinkInRenderedContent);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().off('click', '.textCard_renderedContent a', this._didClickLinkInRenderedContent);
    },

    _didClickLinkInRenderedContent: function _didClickLinkInRenderedContent(e) {
      e.preventDefault();
    }
  });
});